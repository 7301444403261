import { SectionRow, useSection } from '@front/master';
import {
  ERowSpacing,
  TabsWithSelect,
  Container,
  EContainerVariant,
} from '@front/shared/ds';
import clsx from 'clsx';
import React, { useState, useRef } from 'react';

import type { Section } from '@shared/master-types';

type TTab = {
  tabTitle: string;
  rows?: Section['rows'];
};

export type TSpaceRowsWithTabsRow = {
  id?: string;
  blockName?: string;
  blockType: 'space-rows-with-tabs';
  tabs?: TTab[];
};

type TSpaceRowsWithTabsRowProps = {
  row: TSpaceRowsWithTabsRow;
  customSectionRowRenderer?: (row: any, section?: Section) => React.ReactNode; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const SpaceRowsWithTabsRow: React.FC<TSpaceRowsWithTabsRowProps> = ({
  row: rowsWithTabs,
  customSectionRowRenderer,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const {
    sectionProps: { rowSpacingY },
  } = useSection();
  const { tabs = [] } = rowsWithTabs;

  const activeTab = tabs[selectedTabIndex];

  if (!activeTab) {
    return null;
  }

  const { rows = [] } = activeTab;
  const preparedTabs = tabs.map(t => ({ title: t.tabTitle }));

  const onChangeTab = (tabId: number): void => {
    setSelectedTabIndex(tabId);
  };

  return (
    <div className='flex flex-col gap-8 md:gap-12' ref={wrapperRef}>
      <Container variant={EContainerVariant.Full}>
        <div className='flex flex-col'>
          <TabsWithSelect
            tabs={preparedTabs}
            activeIndex={selectedTabIndex}
            wrapperRef={wrapperRef}
            onClick={onChangeTab}
            selectClassName='border-2 border-control-1000 rounded-4xl'
          />
        </div>
      </Container>

      <div
        className={clsx('flex w-full flex-col', {
          'gap-4': rowSpacingY === ERowSpacing.Xs,
          'gap-4 2xl:gap-8': rowSpacingY === ERowSpacing.Base,
          'gap-6 2xl:gap-8': rowSpacingY === ERowSpacing.Md,
          'gap-10 2xl:gap-12': rowSpacingY === ERowSpacing.Lg,
          'gap-8 2xl:gap-14': rowSpacingY === ERowSpacing.Xl,
        })}
      >
        {rows.map(row => (
          <SectionRow
            key={row.id}
            row={row}
            customSectionRowRenderer={customSectionRowRenderer}
          />
        ))}
      </div>
    </div>
  );
};
